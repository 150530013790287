import { useForm, ValidationError } from '@formspree/react';
import React from "react";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("myzykajw");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" prefix="Name" name="name" placeholder="Your Name" />
          </div>
          <ValidationError prefix="name" field="name" errors={state.errors} />
        </div>

        <div className="col-md-6">
          <div className="form-grp">
            <input type="email" name="email" placeholder="Email address" />
          </div>
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>

        <div className="col-md-6">
          <div className="form-grp">
            <input type="number" name="phone" field="phone" placeholder="Phone number" />
          </div>
          <ValidationError name="Phone" field="phone" errors={state.errors} />
        </div>

        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="subject" field="subject" placeholder="Subject" />
          </div>
          <ValidationError prefix="Subject" field="subject" errors={state.errors} />
        </div>
      </div>
      <div className="form-grp">
        <textarea name="message" placeholder="Write message"></textarea>
      </div>
      <ValidationError prefix="Message" field="subject" errors={state.errors} />
      <button type="submit" disabled={state.submitting} className="btn">
        Send a message
      </button>
    </form>
  );
};

export default ContactForm;
