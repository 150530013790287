export const ProjectList = [
  {
    url: '/project-details',
    src: '/img/project/project_img01.jpg',
    src2: '/img/project/h4_project_img01.jpg',
    title: 'Outdoor Home & industrial Appliance',
  },
  {
    url: '/project-details',
    src: '/img/project/project_img02.jpg',
    src2: '/img/project/h4_project_img02.jpg',
    title: 'Furniture Home & industrial Appliance',
  },
  {
    url: '/project-details',
    src: '/img/project/project_img03.jpg',
    src2: '/img/project/h4_project_img03.jpg',
    title: 'Kitchen Home & industrial Appliance',
  },
  {
    url: '/project-details',
    src: '/img/project/project_img04.jpg',
    src2: '/img/project/h4_project_img04.jpg',
    title: 'Car Home & industrial Appliance',
  },
  {
    url: '/project-details',
    src: '/img/project/project_img05.jpg',
    src2: '/img/project/h4_project_img05.jpg',
    title: 'Plumbing Service',
  },
  {
    url: '/project-details',
    src: '/img/project/project_img06.jpg',
    src2: '/img/project/h4_project_img06.jpg',
    title: 'Kitchen Home & industrial Appliance',
  },
]
