import React, { useState } from 'react';

const ExpandableText = ({ children }) => {

  const [expanded, setExpanded] = useState(false)
  const limit = 250;

  if (!children) return null;

  if (children.length <= limit) {
    return <div>{children}</div>
  }

  const summary = expanded ? children : `${children.substring(0, limit)}. . .`;


  return (
    <div>

      <div className='paragraph text-muted'>{summary}</div>
      <button className="btn btn-two mt-2" onClick={() => setExpanded(!expanded)}>
        {expanded ? 'Read less' : 'Read more'}
      </button>
    </div>
  )
}

export default ExpandableText