export const TestimonialItemsArray = [
  {
    src: '/img/ourClient/assurance.jpg',
    title: 'Assurance Developments.',
  },
  {
    src: '/img/ourClient/Bashundhara.jpg',
    title: 'Bashundhara Group',
  },
  {
    src: '/img/ourClient/brac.png',
    title: 'Brac University',
  },
  {
    src: '/img/ourClient/dhakaboatclub.png',
    title: 'Dhaka Boat Club',
  },
  {
    src: '/img/ourClient/evergreen.jpg',
    title: 'Evergreen Hospital',
  },
  {
    src: '/img/ourClient/fakir.png',
    title: 'Fakir Apparels Ltd.',
  },
  {
    src: '/img/ourClient/Greenview_Golf_logo245108.png',
    title: 'Greenview Golf Resort',
  },
  {
    src: '/img/ourClient/kazifarm.png',
    title: 'Kazi Farms chicken',
  },
  {
    src: '/img/ourClient/kfc.png',
    title: 'KFC',
  },
  {
    src: '/img/ourClient/longbeachhotel_logo_color.png',
    title: 'Long beach hotel',
  },
  {
    src: '/img/ourClient/M&J.png',
    title: 'M&J Passion make innnovation',
  },
  {
    src: '/img/ourClient/noman.jpg',
    title: 'Noman Group',
  },
  {
    src: '/img/ourClient/NOURISH.png',
    title: 'NOURISH',
  },
  {
    src: '/img/ourClient/royaltulip.png',
    title: 'Sea Pearl Beach Resort & Spa',
  },

  {
    src: '/img/ourClient/ruinworld.jpg',
    title: 'Ruinworld SL',
  },
  {
    src: '/img/ourClient/simon.jpg',
    title: 'Simon`s Hearitage Resort',
  },
  {
    src: '/img/ourClient/western.png',
    title: 'Western engineering group',
  },
  {
    src: '/img/ourClient/windy-group-logo.png',
    title: 'Windy group',
  },
]
