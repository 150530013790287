import { jarallax } from "jarallax";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { bgImgFromData } from "../../lib/helpers";

const BannerOne = () => {
  useEffect(() => {
    bgImgFromData();
  }, []);

  // jarallax
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  return (
    <section className="banner-area jarallax banner-bg" data-background="/img/banner/banner_bg.png"  >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-7">
            <div className="banner-content">
              <span className="sub-title wow fadeInUp" data-wow-delay=".2s">
                Rare Bangladesh Ltd
              </span>

              <h2 className="title wow fadeInUp" data-wow-delay=".4s">
                Excellence Engineered, Satisfaction Delivered
              </h2>

              <p className="wow fadeInUp" data-wow-delay=".6s">
                Rare Bangladesh Ltd.(RBL) is a company that manufactures and sells different cooling and heating household and industrial appliances or devices.
              </p>

              <div className="banner-btn">
                {/* <Link t="/services" className="btn wow fadeInLeft" data-wow-delay=".8s" >
                  Discover MORE
                </Link> */}
                <Link to="/services" className="btn btn-two wow fadeInRight" data-wow-delay=".8s" >
                  Our service
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
