import React from 'react'

const OurValues = () => {
  return (
    <section className="services-area-two ">
      <div className="container">
        <div className="row justify-content-center pt-25 pb-25">
          <h2 className="title text-center tg-element-title">
            Our Values
          </h2>
          <div className="services-item-two wow fadeInUp" data-wow-delay=".2s">

            <div class="container">
              <div class="row">
                <div class="col-md-3 mb-4">
                  <h6>Customer Commitment</h6>
                  <p>We develop relationships that make a positive difference in our customers’ lives.</p>
                </div>

                <div class="col-md-3 mb-4">
                  <h6>Quality</h6>
                  <p className=''>We provide outstanding products and unsurpassed service that, together, deliver premium value to our customers.</p>
                </div>

                <div class="col-md-3 mb-4">
                  <h6>Teamwork</h6>
                  <p>We work together to meet the needs of our customers and to help the company win.</p>
                </div>

                <div class="col-md-3 mb-4">
                  <h6>Respect for People</h6>
                  <p>We value our people, encourage their development, and reward their performance.</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  )
}

export default OurValues