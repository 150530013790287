import React from "react";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const IntroductionAreaOne = () => {
  return (
    <section id="intro" className="introduction-area pt-100 pb-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="introduction-img-wrap">
              <img src="/img/1.jpg" alt="" />
              <img
                src="/img/w_logo.png"
                data-aos="fade-right"
                alt=""
              />

              <Popup
                trigger={
                  <div className="play-btn">
                    <a
                      href="#intro"
                      className="popup-video"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                }
                position=""
                modal={true}
              >
                <Iframe
                  url="/video/RearWaterRevesio.mp4"
                  width="100%"
                  height="550px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Popup>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="introduction-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style2">
                <span className="sub-title">Our Introduction</span>
                <h2 className="title tg-element-title">
                  Welcome to  <br />
                  RARE Bangladesh Ltd.
                </h2>
              </div>

              <p className="info-one">
                We are committed to providing the best quality services to our customers.
              </p>

              <p >
                We are a professional home & industrial appliance manufacturer company
                that provides services for all types of home & industrial
                appliances.
              </p>


              <div className="introduction-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>We are Committed
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Highly Rated Geyser Manufacturer Company
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Insured & Bonded
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Trusted Professionals
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Residential Home & industrial Appliance
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Commercial Home & industrial Appliance
                  </li>
                </ul>
              </div>

              <div className="introduction-bottom">
                <Link to="/about" className="btn">
                  About Us
                </Link>

                <span className="call-now">
                  <i className="fas fa-phone-alt"></i>
                  <a href="tel:01977-722501">01977-722501</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionAreaOne;
