import React from "react";
import { Link } from "react-router-dom";

const HeaderLogoArea = () => {
  return (
    <div className="header-logo-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-3">
            <div className="logo">
              <Link to="/">
                <img src="/img/logo/logo.png" alt="Logo" width={50} />
              </Link>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9">
            <div className="header-contact-info">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-info-item">
                    <div className="icon">
                      <svg
                        viewBox="0 0 35 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.3942 10.8812L18.2404 0.213432C18.0191 0.0740354 17.7623 0 17.5 0C17.2377 0 16.9809 0.0740354 16.7596 0.213432L0.605823 10.8812C0.41814 11.0018 0.264223 11.1674 0.158332 11.3626C0.0524408 11.5578 -0.00199895 11.7764 5.60959e-05 11.998V29.3331C5.60959e-05 30.0404 0.283708 30.7187 0.788612 31.2189C1.29352 31.719 1.97831 32 2.69236 32H32.3076C33.0217 32 33.7065 31.719 34.2114 31.2189C34.7163 30.7187 34.9999 30.0404 34.9999 29.3331V11.998C35.002 11.7764 34.9476 11.5578 34.8417 11.3626C34.7358 11.1674 34.5819 11.0018 34.3942 10.8812ZM17.5 2.93038L31.2812 12.0313L20.0072 19.9988H14.9928L3.71879 12.0313L17.5 2.93038ZM2.69236 29.3331V14.5816L13.7813 22.4157C14.0065 22.5772 14.2772 22.6646 14.5553 22.6657H20.4447C20.7228 22.6646 20.9935 22.5772 21.2187 22.4157L32.3076 14.5816V29.3331H2.69236Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <h4 className="title">Mail To Us</h4>
                      <Link to="mailto:rarebd2013@gmail.com">rarebd2013@gmail.com</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-info-item">
                    <div className="icon">
                      <svg
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5443 8.90191H23.103V12.4627C23.103 13.4419 23.9038 14.2431 24.8824 14.2431C25.8611 14.2431 26.6618 13.4419 26.6618 12.4627V8.90191H30.2206C31.1993 8.90191 32 8.10074 32 7.12153C32 6.14232 31.1993 5.34115 30.2206 5.34115H26.6618V1.78038C26.6618 0.801172 25.8611 0 24.8824 0C23.9038 0 23.103 0.801172 23.103 1.78038V5.34115H19.5443C18.5656 5.34115 17.7649 6.14232 17.7649 7.12153C17.7649 8.10074 18.5656 8.90191 19.5443 8.90191ZM28.8149 21.8453L24.2952 21.329C23.7638 21.2665 23.2251 21.3254 22.7197 21.5012C22.2143 21.6769 21.7552 21.965 21.377 22.3438L18.1029 25.6197C13.0516 23.0492 8.94581 18.9411 6.37676 13.887L9.66863 10.5933C10.4338 9.82771 10.8074 8.74167 10.6829 7.67345L10.1669 3.18688C10.0708 2.31804 9.65861 1.51493 9.0089 0.930516C8.35918 0.346106 7.51732 0.0212413 6.64367 0.0178038H3.56532C1.55461 0.0178038 -0.118021 1.69136 0.00653656 3.70319C0.949614 18.9077 13.1029 31.0499 28.2811 31.9935C30.2918 32.1181 31.9644 30.4445 31.9644 28.4327V25.3526C31.9822 23.5723 30.6121 22.0411 28.8149 21.8453V21.8453Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <h4 className="title">Call for help:</h4>
                      <Link to="tel:01977-722501">01977-722501</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLogoArea;
