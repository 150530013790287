import React, { useEffect } from "react";
import AboutUs from "../components/AboutUs/AboutUs";
import BreadcrumbArea from "../components/BreadcrumbArea/BreadcrumbArea";
import ServicesAreaSix from "../components/ServicesArea/ServicesAreaSix";
import VideoAreaTwo from "../components/VideoArea/VideoAreaTwo";
import Layout from "../layouts/Layout";
import { gsapTitleAnimation } from "../lib/gsap-lib/gsapTitleAnimation";

const AboutUsPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={1} footer={2}>
        <BreadcrumbArea title={"About Us"} subtitle={"About Us"} />
        <AboutUs />
        <ServicesAreaSix />
        <VideoAreaTwo />
        {/* <OurHistory /> */}
        {/* <TeamOne /> */}
      </Layout>
    </>
  );
};

export default AboutUsPage;
