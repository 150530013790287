import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

const HeaderNavMenu = () => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        <li
          className={cn("menu-item-has-children", {
            active: ["/", "/home-2", "/home-3", "/home-4"].includes(pathname),
          })}  >
          <li className={cn(isActiveClassName("/"))}>
            <Link to="/">Home</Link>
          </li>
        </li>
        <li className={cn(isActiveClassName("/about"))}>
          <Link to="/about">About Us</Link>
        </li>
        <li className={cn(isActiveClassName("/services"))}>
          <Link to="/services">Services</Link>
        </li>

        <li className={cn(isActiveClassName("/contact"))}>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
