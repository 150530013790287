import React from "react";

const TestimonialAreaOneItem = ({ item }) => {
  return (
    <div className="testimonial-item">
      <div className="testimonial-content">
        <div className="testimonial-avatar-info">
          <div className="avatar-thumb">
            <img src={item.src} alt={item.title} />
          </div>

        </div>
        <h6 className="title pt-4">{item.title}</h6>
      </div>
    </div>
  );
};

export default TestimonialAreaOneItem;
