import AOS from 'aos'
import { useEffect } from 'react'
import AnimatedCursor from 'react-animated-cursor'
import { Route, Routes, useLocation } from 'react-router-dom'
import WOW from 'wow.js'
import AboutUsPage from './pages/AboutUsPage'
import EstimatePage from './pages/EstimatePage'
import HomeOne from './pages/Homes/HomeOne'
import ProjectDetailsPage from './pages/Projects/ProjectDetailsPage'
import ProjectPageOne from './pages/Projects/ProjectPageOne'
import ProjectPageTwo from './pages/Projects/ProjectPageTwo'
import ServicesDetailsPage from './pages/Services/ServicesDetailsPage'
import ServicesPageOne from './pages/Services/ServicesPageOne'
import ServicesPageTwo from './pages/Services/ServicesPageTwo'
import TeamDetailsPage from './pages/TeamPages/TeamDetailsPage'
import TeamPage from './pages/TeamPages/TeamPage'

import ContactPage from './pages/ContactPage'

function App() {
  // wow js scroll
  useEffect(() => {
    const wow = new WOW({ boxClass: 'wow', animateClass: 'animated', offset: 0, mobile: false, live: true })
    wow.init()
  }, [])

  // aos scroll
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true, once: true, disable: 'mobile' })
  }, [])

  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])

  return (
    <>
      <AnimatedCursor
        innerSize={10}
        outerSize={32}
        color='0, 124, 251'
        outerAlpha={0}
        innerScale={0}
        outerScale={0}
        trailingSpeed={1000}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'option',
          'textarea',
          'button',
          '.link',
          '.swiper-pagination-bullet',
        ]}
      />

      <Routes>
        <Route path='/' element={<HomeOne />} />
        <Route path='/about' element={<AboutUsPage />} />
        <Route path='/services' element={<ServicesPageOne />} />
        <Route path='/services-2' element={<ServicesPageTwo />} />
        <Route path='/services-details' element={<ServicesDetailsPage />} />
        <Route path='/project' element={<ProjectPageOne />} />
        <Route path='/project-two' element={<ProjectPageTwo />} />
        <Route path='/project-details' element={<ProjectDetailsPage />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/team-details' element={<TeamDetailsPage />} />
        <Route path='/estimate' element={<EstimatePage />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
    </>
  )
}

export default App
